import React from "react";
import ReactDOM from "react-dom/client";
import "../src/styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ContextBundler from "./ContextBundler";
import { ApolloProvider } from "@apollo/client";
import client from "./utilities/apolloClient";
import { ConfigProvider } from "antd";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactGA from "react-ga";
import { HelmetProvider } from "react-helmet-async";
import "./i18n";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});
if (process.env.REACT_ENV === "production") {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_TAG_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <>
    <HelmetProvider>
      <ApolloProvider client={client}>
        <ContextBundler>
          <BrowserRouter>
            <ConfigProvider
              getPopupContainer={(trigger) =>
                trigger?.parentElement || document.body
              }
            >
              <App />
            </ConfigProvider>
          </BrowserRouter>
        </ContextBundler>
      </ApolloProvider>
    </HelmetProvider>
  </>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
