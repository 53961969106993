const setNotification = (oldState, payload) => {
  const { notifications: newNoti } = payload;
  return {
    ...oldState,
    notifications: newNoti,
  };
};

const clearNotification = (oldState) => {
  return {
    ...oldState,
    notifications: 0,
  };
};

const setRecentProject = (oldState, payload) => {
  const { recentProjectID: newRecent } = payload;
  return {
    ...oldState,
    recentProjectID: newRecent,
  };
};

const showModal = (oldState, payload) => {
  const { modalConfig: newModalConfig } = payload;
  return {
    ...oldState,
    isShowModal: true,
    modalConfig: newModalConfig,
  };
};
const hideModal = (oldState) => {
  return {
    ...oldState,
    isShowModal: false,
    modalConfig: null,
  };
};

const setCurrentUser = (oldState, payload) => {
  const { currentUser: newUserDetail } = payload;
  return {
    ...oldState,
    currentUser: newUserDetail,
  };
};

const updateCurrentBalance = (oldState, payload) => {
  return {
    ...oldState,
    currentUser: {
      ...oldState.currentUser,
      balance: payload.value,
    },
  };
};

const showGlobalLoading = (oldState, payload) => {
  return {
    ...oldState,
    globalLoading: true,
  };
};

const hideGlobalLoading = (oldState, payload) => {
  return {
    ...oldState,
    globalLoading: false,
  };
};

const setTimeStudyCounter = (oldState, payload) => {
  const { timeStudyCounter } = payload?.payload;
  return {
    ...oldState,
    timeStudyCounter: timeStudyCounter,
  };
};
const clearTimeStudyCounter = (oldState) => {
  return {
    ...oldState,
    timeStudyCounter: 0,
  };
};

export {
  setNotification,
  clearNotification,
  setRecentProject,
  showModal,
  hideModal,
  setCurrentUser,
  updateCurrentBalance,
  showGlobalLoading,
  hideGlobalLoading,
  setTimeStudyCounter,
  clearTimeStudyCounter,
};
