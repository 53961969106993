const getRequiredText = (getText, i18n, key) => {
  return i18n.format(
    getText("is-required", { key: getText(key) }),
    "capitalize"
  );
};
const displayTextAmount = (val) => {
  return Number(val).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const replaceLangAudience = (key) => key?.replaceAll(" ", "-").toLowerCase();
const replaceOptionBox = (key) => key?.replaceAll(" ", "-").toLowerCase();

export {
  getRequiredText,
  displayTextAmount,
  replaceLangAudience,
  replaceOptionBox,
};
