import "./Sidebar.css";
import { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTE } from "../../config/common";
import CommonFooter from "../common/CommonFooter";
import { Avatar } from "antd";
import {
  CommonContext,
  CommonDispatchContext,
} from "../../stateManagement/context/commonContext";
import { showTopupModal } from "../../utilities/renderUtil";
import { displayTextAmount } from "../../utilities/textUtil";

const SidebarButton = ({ route, title, icon }) => {
  const { t: getText, i18n } = useTranslation();
  const location = useLocation();
  const regex = /reward/i;
  let isMatch = false;
  if (ROUTE.REWARDS === route) {
    isMatch = regex.test(location.pathname);
  }
  return (
    <NavLink className={`nav-button ${isMatch ? "active" : ""}`} to={route}>
      <div className="nav-button-icon-container">
        <div className={`${icon}-icon nav-button-icon`} />
      </div>
      <div className="nav-button-title-container">
        <div className="nav-button-title">{title}</div>
        <div className="flex">
          <div className="nav-button-subtitle">
            {i18n.format(getText("view-all"), "capitalFirstEach")}
          </div>
          <div className="arrow-right-button" />
        </div>
      </div>
    </NavLink>
  );
};

const Sidebar = (props) => {
  const { t: getText, i18n } = useTranslation();
  const { currentUser } = useContext(CommonContext);
  const dispatch = useContext(CommonDispatchContext);
  const amount = displayTextAmount(currentUser?.currentBalance || 0);

  return (
    <div id="sidebar-container">
      <div id="sidebar-menu-container">
        <SidebarButton
          route={ROUTE.PROJECTS}
          icon="folder"
          title={i18n.format(getText("my-folders"), "uppercase")}
        />
        <SidebarButton
          route={ROUTE.SHARED_WITH_ME}
          icon="shared-with-me"
          title={i18n.format(getText("shared-with-me"), "uppercase")}
        />
        <SidebarButton
          route={ROUTE.REWARDS}
          icon="rewards"
          title={i18n.format(getText("my-rewards"), "uppercase")}
        />
      </div>
      <div id="wallet-sidebar-menu-container">
        <div id="wallet-sidebar-container">
          <div id="balance-title">
            {i18n.format(getText("balance"), "uppercase")}
          </div>
          <div id="balance-value">{amount}</div>
          <div
            id="topup-button"
            onClick={(e) => {
              showTopupModal(dispatch, e);
            }}
          >
            <Avatar
              id="wallet-add-icon"
              size={42}
              src={<img src="/images/sidebar/empty-wallet-add.svg" alt="" />}
            />
            <div id="topup-title">
              {i18n.format(getText("topup"), "capitalFirstEach")}
            </div>
          </div>
        </div>
      </div>
      <div id="footer-label-contaner">
        <CommonFooter />
      </div>
    </div>
  );
};

export default Sidebar;
