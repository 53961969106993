// for gql query config// for gql query config
import { gql } from "@apollo/client";

const LOGOUT = gql`
  query logout {
    logout {
      messageCode
      message
    }
  }
`;

// project dashboard
const LIST_PROJECT = gql`
  query listProject($offset: Int, $limit: Int) {
    projectSurveys(offset: $offset, limit: $limit) {
      totalPage
      totalRecord
      currentPage
      limit
      data {
        uid
        name
        surveyType
        status
        paymentStatus
        favorite
        totalSubmission
        totalParticipantLimit
        totalDone
        totalProgress
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
    }
  }
`;

// audience
// scalepoint
const GET_PROJECT = gql`
  fragment LogicFragment on Logic {
    logicUid
    uuid
    type
    cubeUuid
    condition {
      conditionUid
      uuid
      conjunction
      conditionType
      questionUuid
      questionType
      choiceUuid
      scaleUuid
      mode
      operator
      value
      ignoreCase
      order
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
    randomlyPresent
    countPresent
    countPreview
    order
    collapse
  }
  query projectSurveyById($projectUid: String!, $cubeUid: String) {
    projectSurveyById(projectUid: $projectUid, cubeUid: $cubeUid) {
      projectUid
      surveyType
      name
      status
      publishDate
      favorite
      description
      device
      rejectMessage
      paymentStatus
      paymentMessage
      audienceType
      totalParticipantLimit
      estimatedDuration
      costPerParticipant
      additionalIntensives
      totalAdditionalIncentives
      totalBudget
      costUnit
      vat
      totalExpense
      totalSubmission
      totalDone
      totalProgress
      createdBy
      createdAt
      updatedBy
      updatedAt
      cube {
        cubeUid
        uuid
        cubeName
        minimumTime
        maximumTime
        order
        question {
          questionUid
          uuid
          title
          type
          answerType
          format
          matrixType
          slideType
          mandatory
          maxStar
          min
          max
          gridLines
          scale {
            scaleUid
            uuid
            label
            point
            naming
            order
          }
          videoUrl
          order
          choice {
            choiceUid
            uuid
            label
            rightLabel
            order
            isOther
          }
          imageName
          imageUrl
          imagePath
          imageType
        }
      }
      logic {
        ...LogicFragment
        logic {
          ...LogicFragment
          logic {
            ...LogicFragment
            logic {
              ...LogicFragment
            }
          }
        }
      }
    }
  }
`;

const LIST_SUBMISSION = gql`
  query listSubmission($uid: String!, $offset: Int, $limit: Int) {
    submissionSurvey(projectUid: $uid, offset: $offset, limit: $limit) {
      totalPage
      totalRecord
      currentPage
      limit
      data {
        submissionUid
        participantUid
        createdAt
        finishedAt
        timeStudy
        submissionStatus
      }
    }
  }
`;

// shared project dashboard

const LIST_SHARE_PROJECT = gql`
  query listShareProject($offset: Int, $limit: Int) {
    sharedWithMeProjectSurveys(offset: $offset, limit: $limit) {
      totalPage
      totalRecord
      currentPage
      limit
      data {
        uid
        name
        status
        favorite
        totalSubmission
        totalDone
        totalProgress
        totalParticipantLimit
        createdBy
        createdAt
        updatedBy
        updatedAt
        owner {
          uid
          email
          firstname
          lastname
          middlename
        }
      }
    }
  }
`;

// profile
const PROFILE = gql`
  query getProfile {
    profile {
      uid
      email
      profilePic
      firstname
      middlename
      lastname
      dateOfBirth
      language
      role
      language
      currentBalance
      currentCredit
      verifyEmail
      nationality
      gender
      address
      identificationNumber
      phoneNumber
      overAge
      acceptConditions
      createdBy
      createdAt
      updatedBy
      updatedAt
      changeEmailGeneratedAt
      changeEmailPending
      changeEmailStatus
      changeEmailVerifyKey
    }
  }
`;

const GET_TOTAL_PROJECT_REPORT = gql`
  query totalSurveyReport($uid: String!) {
    totalSurveyReport(uid: $uid) {
      publishDate
      costPerParticipant
      totalEligible
      totalParticipant
      totalSubmission
      totalParticipantLimit
    }
  }
`;

const GET_PROJECT_SURVEY_SETTINGS = gql`
  query GetProjectSurveyByID($projectUid: String!) {
    projectSurveyById(projectUid: $projectUid) {
      projectUid
      surveyType
      name
      description
      device
      status
      paymentStatus
      totalParticipantLimit
      estimatedDuration
      costPerParticipant
      additionalIntensives
      totalAdditionalIncentives
      totalBudget
      costUnit
      vat
      totalExpense
      totalSubmission
      totalDone
      totalProgress
      audienceType
      paymentType
      owner {
        firstname
        lastname
      }
    }
  }
`;

const LIST_ALL_ACCOUNT = gql`
  query listAccount {
    accounts {
      uid
      role
      firstname
      middlename
      lastname
      email
    }
  }
`;

const BALANCE = gql`
  query getBalance {
    profile {
      currentBalance
    }
  }
`;

const STUDY_PROJECT_BY_ID = gql`
  fragment LogicFragment on Logic {
    logicUid
    uuid
    type
    cubeUuid
    countPresent
    countPreview
    condition {
      uuid
      choiceUuid
      questionUuid
      operator
      value
      questionType
      conditionType
      mode
      scaleUuid
    }
    randomlyPresent
    order
    collapse
  }
  query projectStudyById($projectUid: String!) {
    projectStudyById(projectUid: $projectUid) {
      projectUid
      name
      status
      studyUid
      currentLogic
      currentStage
      timeSpent
      timeStudy
      shuffleLogic
      cube {
        cubeUid
        uuid
        question {
          questionUid
          uuid
          answerType
          type
          scale {
            scaleUid
            uuid
            label
            point
            naming
            order
          }
          answer {
            value
            checked
            multiValue
          }
          choice {
            choiceUid
            uuid
            label
            rightLabel
            order
            answer {
              value
              checked
              multiValue
              scaleUid
              scaleLabel
            }
          }
        }
      }
      logic {
        ...LogicFragment
        logic {
          ...LogicFragment
          logic {
            ...LogicFragment
            logic {
              ...LogicFragment
            }
          }
        }
      }
    }
  }
`;

const GET_PROJECT_REVIEW = gql`
  fragment LogicFragment on Logic {
    logicUid
    uuid
    type
    cubeUuid
    countPresent
    countPreview
    condition {
      uuid
      choiceUuid
      questionUuid
      operator
      value
      questionType
      conditionType
      mode
      scaleUuid
    }
    randomlyPresent
    order
    collapse
  }
  query getProjectPreview($uid: String!) {
    projectSurveyById(projectUid: $uid) {
      projectUid
      surveyType
      name
      cube {
        uuid
      }
      logic {
        ...LogicFragment
        logic {
          ...LogicFragment
          logic {
            ...LogicFragment
          }
        }
      }
    }
  }
`;

const GET_CUBE_PREVIEW = gql`
  query getQube($projectUid: String!, $cubeUuid: String!) {
    cubeByUuid(projectUid: $projectUid, cubeUuid: $cubeUuid) {
      cubeUid
      uuid
      cubeName
      minimumTime
      maximumTime
      order
      question {
        questionUid
        uuid
        title
        type
        answerType
        format
        matrixType
        slideType
        mandatory
        maxStar
        min
        max
        gridLines
        imageUrl
        videoUrl
        order
        scale {
          scaleUid
          uuid
          label
          point
          naming
          order
        }
        choice {
          choiceUid
          uuid
          label
          rightLabel
          order
          isOther
        }
      }
    }
  }
`;

const GET_CUBE_STUDY = gql`
  query getQubeStudy($projectUid: String!, $cubeUuid: String!) {
    cubeStudyByUuid(projectUid: $projectUid, cubeUuid: $cubeUuid) {
      cubeUid
      uuid
      cubeName
      minimumTime
      maximumTime
      order
      question {
        questionUid
        uuid
        title
        type
        answerType
        format
        matrixType
        slideType
        mandatory
        scale {
          scaleUid
        }
        maxStar
        min
        max
        gridLines
        videoUrl
        imageUrl
        order
        scale {
          scaleUid
          uuid
          label
          point
          naming
          order
        }
        choice {
          choiceUid
          uuid
          label
          rightLabel
          order
          isOther
          answer {
            value
            checked
            multiValue
            scaleUid
            scaleLabel
          }
        }
        answer {
          value
          checked
          multiValue
        }
      }
    }
  }
`;

const QUESTION_LIST_BY_PROJECT = gql`
  query questionListByProject($projectUid: String!) {
    questionListByProject(projectUid: $projectUid) {
      questionUuid
      title
      questionType
      matrixType
    }
  }
`;

const CHOICE_LIST_BY_QUESTION = gql`
  query choiceListByQuestion($projectUid: String!, $questionUuid: String!) {
    choiceListByQuestion(projectUid: $projectUid, questionUuid: $questionUuid) {
      choiceUuid
      label
      scaleUuid
      mode
    }
  }
`;

const GET_PROJECT_LOGIC = gql`
  fragment LogicFragment on Logic {
    logicUid
    uuid
    type
    cubeUuid
    condition {
      conditionUid
      uuid
      conjunction
      conditionType
      questionUuid
      questionType
      choiceUuid
      scaleUuid
      mode
      operator
      value
      ignoreCase
      order
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
    randomlyPresent
    countPresent
    countPreview
    order
    collapse
  }
  query GetProjectSurveyById($projectUid: String!) {
    projectSurveyById(projectUid: $projectUid) {
      projectUid
      surveyType
      name
      updatedAt
      status
      paymentStatus
      logic {
        ...LogicFragment
        logic {
          ...LogicFragment
          logic {
            ...LogicFragment
            logic {
              ...LogicFragment
            }
          }
        }
      }
    }
  }
`;

const CUBE_NAME_AND_ORDER = gql`
  query cubeByUuid($projectUid: String!, $cubeUuid: String!) {
    cubeByUuid(projectUid: $projectUid, cubeUuid: $cubeUuid) {
      uuid
      cubeName
      order
      question {
        questionUid
      }
    }
  }
`;

const CUBE_LOGIC = gql`
  query projectSurveyByIdCube($projectUid: String!) {
    projectSurveyById(projectUid: $projectUid) {
      cube {
        cubeUid
        cubeName
        order
        uuid
      }
    }
  }
`;

const GET_AUDIENCE_LIST = gql`
  query Audiences($projectUid: String!) {
    audiences(projectUid: $projectUid) {
      audienceUid
      typeName
      need {
        questionSubject
        choice {
          choiceValue
          choiceTitle
        }
      }
      needless {
        questionSubject
        choice {
          choiceValue
          choiceTitle
        }
      }
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;

const GET_TOTAL_MATCHING_PARTICIPANTS = gql`
  query TotalMatchingParticipants(
    $projectUid: ID!
    $audienceType: AudienceType!
  ) {
    totalMatchingParticipants(
      projectUid: $projectUid
      audienceType: $audienceType
    ) {
      totalMatchingParticipants
    }
  }
`;

const GET_SCREENING_AUDIENCES = gql`
  query ScreeningAudiences($audienceUid: String, $projectUid: String!) {
    screeningAudiences(audienceUid: $audienceUid, projectUid: $projectUid) {
      typeUid
      typeName
      order
      question {
        questionUid
        questionTitle
        questionSubject
        order
        answerColumn
        answerForm
        multiAnswer
        choice {
          choiceUid
          choiceValue
          choiceTitle
          order
          checked
        }
      }
    }
  }
`;

const PROJECT_SHARED_RESEARCHER_LIST = gql`
  query projectSharedResearcherList($projectUid: String!) {
    projectSharedResearcherList(projectUid: $projectUid) {
      sharedList
    }
  }
`;

const LIST_RESEARCHER_TRANSACTION = gql`
  query researcherTransaction(
    $offset: Int
    $limit: Int
    $transactionStatus: TransactionStatus!
  ) {
    researcherTransaction(
      offset: $offset
      limit: $limit
      transactionStatus: $transactionStatus
    ) {
      totalRecord
      currentPage
      limit
      totalPage
      data {
        transactionUid
        transactionName
        transactionType
        transactionStatus
        transactionAmountSummary
        transactionAmount
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
    }
  }
`;

const GET_NOTIFICATION = gql`
  query getNotification($offset: Int, $limit: Int) {
    notifications(offset: $offset, limit: $limit) {
      totalRecord
      currentPage
      limit
      totalPage
      totalUnread
      data {
        notificationUid
        notificationProcess
        notificationPath
        messageEN
        messageTH
        flagRead
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
    }
  }
`;

const LIST_REWARDS = gql`
  query Rewards($offset: Int, $limit: Int) {
    rewards(offset: $offset, limit: $limit) {
      totalRecord
      currentPage
      limit
      totalPage
      data {
        uid
        name
        code
        status
        description
        numberRedeem
        numberPrizes
        limitPerson
        expirationDate
        expirationDateUnlimited
        numberPrizeUnlimited
        limitPersonUnlimited
        receiptDescription
        createdAt
        rewardFiles {
          fileName
          filePath
          uid
        }
      }
    }
  }
`;
const MY_REWARDS = gql`
  query MyRewards($offset: Int, $limit: Int) {
    myRewards(offset: $offset, limit: $limit) {
      totalRecord
      currentPage
      limit
      totalPage
      data {
        uid
        name
        code
        status
        description
        numberRedeem
        numberPrizes
        limitPerson
        expirationDate
        expirationDateUnlimited
        numberPrizeUnlimited
        limitPersonUnlimited
        receiptDescription
        createdAt
        updatedAt
        favorite
        redeems {
          status
          uid
        }
        rewardFiles {
          fileName
          filePath
          uid
        }
      }
    }
  }
`;
const GET_REWARD = gql`
  query reward($id: String!) {
    reward(id: $id) {
      uid
      name
      description
      numberRedeem
      numberPrizes
      limitPerson
      status
      receiptDescription
      expirationDate
      expirationDateUnlimited
      numberPrizeUnlimited
      limitPersonUnlimited
      code
      createdBy
      updatedBy
      updatedAt
      createdAt
      eligibleParticipents
      rewardFiles {
        uid
        fileName
        filePath
      }
      redeems {
        uid
        createdBy
        updatedBy
        updatedAt
        createdAt
        account {
          uid
          email
          firstname
          lastname
          role
        }
        status
      }
      account {
        uid
        firstname
        lastname
        email
        role
      }
    }
  }
`;
const GET_REDEEMS = gql`
  query Redeems($rewardUid: String!, $limit: Int, $offset: Int) {
    redeems(rewardUid: $rewardUid, limit: $limit, offset: $offset) {
      data {
        status
        uid
        createdAt
        createdBy
        updatedAt
        updatedBy
        account {
          email
          firstname
          lastname
          uid
        }
      }
      currentPage
      limit
      totalPage
      totalRecord
    }
  }
`;

const FILTER_PARTNER_ORGANIZATION = gql`
  query SearchPartnerOrganization($keyword: String) {
    searchPartnerOrganization(keyword: $keyword) {
      choiceUid
      choiceValue
    }
  }
`;

export {
  LOGOUT,
  LIST_RESEARCHER_TRANSACTION,
  LIST_ALL_ACCOUNT,
  LIST_PROJECT,
  LIST_SHARE_PROJECT,
  LIST_SUBMISSION,
  GET_PROJECT,
  PROFILE,
  GET_PROJECT_SURVEY_SETTINGS,
  GET_TOTAL_PROJECT_REPORT,
  BALANCE,
  STUDY_PROJECT_BY_ID,
  GET_PROJECT_REVIEW,
  GET_CUBE_PREVIEW,
  GET_CUBE_STUDY,
  QUESTION_LIST_BY_PROJECT,
  CHOICE_LIST_BY_QUESTION,
  GET_PROJECT_LOGIC,
  CUBE_NAME_AND_ORDER,
  CUBE_LOGIC,
  PROJECT_SHARED_RESEARCHER_LIST,
  GET_AUDIENCE_LIST,
  GET_TOTAL_MATCHING_PARTICIPANTS,
  GET_SCREENING_AUDIENCES,
  GET_NOTIFICATION,
  LIST_REWARDS,
  GET_REWARD,
  GET_REDEEMS,
  MY_REWARDS,
  FILTER_PARTNER_ORGANIZATION,
};
