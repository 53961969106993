import { useState, useEffect, useMemo, useContext } from "react";
import { CommonContext } from "../../stateManagement/context/commonContext";
import { Space, Avatar, Badge, Dropdown, Popover, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import { MoreOutlined } from "@ant-design/icons";

// import constant
import { CONSTANT_VALUE, ROUTE } from "../../config/common";
import { clearToken } from "../../utilities/authenUtil";
import { notEmpty } from "../../utilities/util";
import _ from "lodash";

import { displayTextAmount } from "../../utilities/textUtil";
// import style
import "./Navbar.css";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_NOTIFICATION, LOGOUT } from "../../graphql/query";
import {
  UPDATE_READ_FLAG,
  UPDATE_READ_FLAG_ALL,
  DELETE_NOTIFICATION_ALL,
} from "../../graphql/mutation";
import {
  notificationDataToFrontend,
  notificationToFrontend,
} from "../../models/notificationModel";
import { io } from "socket.io-client";

const logoutCallback = () => {
  clearToken();
  if (process.env.REACT_APP_LANDING_PAGE) {
    window.location.replace(process.env.REACT_APP_LANDING_PAGE);
  } else {
    window.location.replace(ROUTE.UNAUTHEN);
  }
};

const Notification = () => {
  const [notifications, setNotification] = useState({});
  const { currentUser } = useContext(CommonContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [updateReadFlag] = useMutation(UPDATE_READ_FLAG);
  const [updateReadFlagAll] = useMutation(UPDATE_READ_FLAG_ALL);
  const [deleteNotificationAll] = useMutation(DELETE_NOTIFICATION_ALL);
  const [showNoti, setShowNoti] = useState(false);
  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_NOTI_PATH, {
      reconnectionDelayMax: 1000,
      reconnectionDelay: 1000,
      reconnectionAttempts: Infinity,
    });
    socket.on("connect", function () {
      socket.emit("update_socket_id", currentUser?.uid);
    });
    socket.on("notification", function (data) {
      const newNotiData = notificationDataToFrontend(data?.data);
      setNotification((prev) => ({
        ...prev,
        unread: data.totalUnread,
        dataList: [newNotiData, ...prev.dataList],
      }));
    });
    return () => socket.close();
  }, [currentUser]);
  useQuery(GET_NOTIFICATION, {
    fetchPolicy: "network-only",
    variables: {
      offset: currentPage,
      limit: CONSTANT_VALUE.LIMIT_NOTI,
    },
    onCompleted: (data) => {
      const transformedData = notificationToFrontend(data?.notifications);
      setNotification((prev) => ({
        ...transformedData,
        dataList: _.uniqBy(
          [...(prev?.dataList || []), ...transformedData?.dataList],
          "uid"
        ),
      }));
    },
  });
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const getText = (text, format, options) =>
    i18n.format(t(text, options), format);
  const notiList = useMemo(
    () => notifications?.dataList || [],
    [notifications]
  );

  const notificationItem = (data = {}) => {
    const { uid, flagRead, path } = data;
    return (
      <div
        key={uid}
        className="notification-item-container select-none"
        onClick={async () => {
          if (!flagRead) {
            await updateReadFlag({ variables: { notificationUid: uid } });
            const newList = notiList?.reduce((acc, noti) => {
              const newNoti = { ...noti };
              if (uid === noti.uid) {
                newNoti.flagRead = true;
              }
              return [...acc, newNoti];
            }, []);
            setNotification((prev) => ({
              ...prev,
              unread: prev.unread >= 0 ? prev.unread - 1 : 0,
              dataList: newList,
            }));
          }
          navigate(path);
          setShowNoti(false);
        }}
      >
        <div>{data?.[`message${i18n.language?.toUpperCase()}`]}</div>
        {!flagRead ? (
          <div className="flag-read" />
        ) : (
          <div className="flag-read-temp" />
        )}
      </div>
    );
  };

  const items = [
    {
      label: getText("marl_all_as_read"),
      key: "marl_all_as_read",
    },
    {
      label: getText("delete_all"),
      key: "delete_all",
    },
  ];

  const onClickNotification = async ({ key }) => {
    switch (key) {
      case "delete_all":
        await deleteNotificationAll();
        setNotification((prev) => ({
          ...prev,
          unread: 0,
          totalRecord: 0,
          dataList: [],
        }));
        break;
      default:
        await updateReadFlagAll();
        const newList = notiList?.reduce((acc, noti) => {
          const newNoti = { ...noti };
          newNoti.flagRead = true;
          return [...acc, newNoti];
        }, []);
        setNotification((prev) => ({
          ...prev,
          unread: 0,
          dataList: newList,
        }));
        break;
    }
  };

  const notificationContent = (
    <div id="notification-content-container">
      <div id="notification-header">
        <div>{getText("notifications", "uppercase")}</div>
        <div className="notification-tool">
          <Dropdown
            menu={{ items, onClick: onClickNotification }}
            trigger={["click"]}
            placement="bottomRight"
            className="border-none text-white hover:text-white bg-transparent cursor-pointer"
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <MoreOutlined color="#FFFFFF" />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
      <div id="notification-body">
        {notEmpty(notiList) ? (
          notiList.map(notificationItem)
        ) : (
          <div
            id="empty-noti-container"
            className="text-center lightgray select-none"
          >
            {getText("empty-label", "capitalize", {
              key: getText("notification"),
            })}
          </div>
        )}
      </div>
      {notifications.dataList?.length < notifications.totalRecord && (
        <div
          className="more-noti-button select-none"
          onClick={() => setCurrentPage((prev) => prev + 1)}
        >
          {getText("notifications-more", "capitalize")}
        </div>
      )}
    </div>
  );
  return (
    <div id="notification-container" className="pointer">
      <Popover
        open={showNoti}
        overlayClassName="notification-overlay"
        trigger="click"
        content={notificationContent}
        placement="bottomRight"
        onOpenChange={(v) => setShowNoti(v)}
      >
        <Badge offset={[-2, 4]} count={notifications?.unread}>
          <Avatar
            className="noti-icon"
            size={42}
            src={<img alt="" src="/images/common/notification-icon.svg" />}
          />
        </Badge>
      </Popover>
    </div>
  );
};

const Wallet = () => {
  const { currentUser } = useContext(CommonContext);
  const amount = displayTextAmount(currentUser?.currentBalance || 0);
  return (
    <div id="wallet-container" className="pointer">
      <Space>
        <Avatar
          className="wallet-icon"
          size={42}
          src={<img alt="" src="/images/common/wallet-money-icon.svg" />}
        />
        <div>
          <div id="amount-title" className="balance-bath">
            {amount}
          </div>
          <div id="amount-desc" className="balance-bath">
            Baht
          </div>
        </div>
      </Space>
    </div>
  );
};

const Credit = () => {
  const { currentUser } = useContext(CommonContext);
  const balance = displayTextAmount(currentUser?.currentCredit || 0);
  return (
    <div id="credit-container" className="pointer">
      <Space>
        <Avatar
          className="credit-icon"
          size={42}
          src={<img alt="" src="/images/common/credit-money-icon.svg" />}
        />
        <div>
          <div id="amount-title" className="balance-credit">
            {balance}
          </div>
          <div id="amount-desc" className="balance-credit">
            i-Credit
          </div>
        </div>
      </Space>
    </div>
  );
};

const CurrentUser = () => {
  const { currentUser } = useContext(CommonContext);
  const name = currentUser?.fullname;
  const { t: getText, i18n } = useTranslation();
  const role = i18n.format(getText("researcher"), "capitalize");
  const [logout] = useLazyQuery(LOGOUT, {
    fetchPolicy: "network-only",
    onError: () => {
      logoutCallback();
    },
    onCompleted: () => {
      logoutCallback();
    },
  });

  const items = [
    {
      key: "account",
      label: (
        <Link to={ROUTE.ACCOUNT.ROOT} className="dropdown-menu-title">
          {i18n.format(getText("account"), "capitalFirstEach")}
        </Link>
      ),
      icon: <img alt="" src="/images/navbar/profile-circle.svg" />,
    },
    // {
    //   key: "refresh-account",
    //   label: (
    //     <Link to="/" className="dropdown-menu-title">
    //       {i18n.format(getText("refresh-account"), "capitalFirstEach")}
    //     </Link>
    //   ),
    //   icon: <img alt="" src="/images/navbar/refresh-right-square.svg" />,
    // },
    {
      key: "logout",
      label: (
        <div
          id="logout-menu"
          className="dropdown-menu-title"
          onClick={() => {
            logout();
          }}
        >
          {i18n.format(getText("logout"), "capitalFirstEach")}
        </div>
      ),
      icon: <img alt="" src="/images/navbar/logout.svg" />,
    },
  ];
  // const menu = (
  //   <Menu
  //     onClick={({ key }) => {
  //       switch (key) {
  //         case "logout":
  //           logout();
  //           break;
  //         default:
  //       }
  //     }}
  //     items={[
  //       {
  //         key: "account",
  //         label: (
  //           <Link to={ROUTE.ACCOUNT.ROOT} className="dropdown-menu-title">
  //             {i18n.format(getText("account"), "capitalFirstEach")}
  //           </Link>
  //         ),
  //         icon: <img alt="" src="/images/navbar/profile-circle.svg" />,
  //       },
  //       {
  //         key: "refresh-account",
  //         label: (
  //           <Link to="/" className="dropdown-menu-title">
  //             {i18n.format(getText("refresh-account"), "capitalFirstEach")}
  //           </Link>
  //         ),
  //         icon: <img alt="" src="/images/navbar/refresh-right-square.svg" />,
  //       },
  //       {
  //         key: "logout",
  //         label: (
  //           <div id="logout-menu" className="dropdown-menu-title">
  //             {i18n.format(getText("logout"), "capitalFirstEach")}
  //           </div>
  //         ),
  //         icon: <img alt="" src="/images/navbar/logout.svg" />,
  //       },
  //     ]}
  //   />
  // );
  return (
    <Dropdown
      overlayClassName="user-profile-dropdown"
      menu={{ items }}
      trigger="click"
    >
      <div
        id="current-user-container"
        className={`pointer${currentUser?.profilePic ? " has-avatar" : ""}`}
      >
        <Space>
          <Avatar
            className="user-icon"
            size={42}
            src={
              <img
                alt=""
                src={currentUser?.profilePic || "/images/common/user-icon.svg"}
              />
            }
          />
          <div>
            <div className="username-title">{name}</div>
            <div className="user-role-title">{role}</div>
          </div>
        </Space>
        <img height={6} alt="" src="/images/common/arrow-down.svg" />
      </div>
    </Dropdown>
  );
};

const Navbar = () => {
  const { balance: amount, currentCredit: credit } = useContext(CommonContext);
  const navigate = useNavigate();
  return (
    <div id="navbar-container">
      <div id="navbar-leading">
        <img
          className="pointer"
          alt=""
          src="/images/common/inline-icon.svg"
          onClick={() => navigate(ROUTE.PROJECTS)}
        />
      </div>
      <div id="navbar-trailing">
        <Space size="large">
          {/* Phase2 */}
          {/* <SearchSomething /> */}
          <Notification />
          <Space size="middle">
            <Credit credit={credit} />
            <Wallet amount={amount} />
          </Space>
          <CurrentUser />
        </Space>
      </div>
    </div>
  );
};

export default Navbar;
