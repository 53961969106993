// import handler function
import {
  setNotification,
  clearNotification,
  setRecentProject,
  showModal,
  hideModal,
  setCurrentUser,
  updateCurrentBalance,
  showGlobalLoading,
  hideGlobalLoading,
  setTimeStudyCounter,
  clearTimeStudyCounter,
} from "../handlers/commonHandler";
// import handler function
import { ACTIONS } from "../actions/commonAction";

const CommonReducer = (state, { type, ...action }) => {
  switch (type) {
    case ACTIONS.SET_NOTIFICATION:
      return setNotification(state, action);
    case ACTIONS.CLEAR_NOTIFICATION:
      return clearNotification(state);
    case ACTIONS.SET_RECENT_PROJECT:
      return setRecentProject(state, action);
    case ACTIONS.SHOW_CONFIRM_MODAL:
      return showModal(state, action);
    case ACTIONS.HIDE_CONFIRM_MODAL:
      return hideModal(state);
    case ACTIONS.SET_CURRENT_USER:
      return setCurrentUser(state, action);
    case ACTIONS.UPDATE_CURRENT_BALANCE:
      return updateCurrentBalance(state, action);
    case ACTIONS.SHOW_GLOBAL_LOADING:
      return showGlobalLoading(state, action);
    case ACTIONS.HIDE_GLOBAL_LOADING:
      return hideGlobalLoading(state, action);
    case ACTIONS.SET_TIMESTUDY_COUNTER:
      return setTimeStudyCounter(state, action);
    case ACTIONS.CLEAR_TIMESTUDY_COUNTER:
      return clearTimeStudyCounter(state, action);
    default:
      return state;
  }
};

export default CommonReducer;
