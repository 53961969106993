const ACTIONS = {
  SET_NOTIFICATION: "set-notification",
  CLEAR_NOTIFICATION: "clear-notification",
  SET_CURRENT_USER: "set-current-user",
  REMOVE_CURRENT_USER: "remove-current-user",
  SET_RECENT_PROJECT: "set-recent-project",
  SHOW_CONFIRM_MODAL: "show-confirm-modal",
  HIDE_CONFIRM_MODAL: "hide-confirm-modal",
  UPDATE_CURRENT_BALANCE: "update-current-balance",
  SHOW_GLOBAL_LOADING: "show-global-loading",
  HIDE_GLOBAL_LOADING: "hide-global-loading",
  SET_TIMESTUDY_COUNTER: "set-global-loading",
  CLEAR_TIMESTUDY_COUNTER: "clear-global-loading",
};

export { ACTIONS };
