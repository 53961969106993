import { ApolloClient, InMemoryCache, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { notification } from "antd";
import { ROUTE } from "../config/common";
import Cookies from "js-cookie";
import {
  clearToken,
  getRefreshToken,
  getToken,
  isTokenExpired,
  setToken,
} from "./authenUtil";
import { createUploadLink } from "apollo-upload-client";
import i18n from "i18next";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { nanoid } from "nanoid";

const refreshTokenLink = new TokenRefreshLink({
  accessTokenField: `refreshToken`,
  isTokenValidOrUndefined: () => {
    const token = getToken();
    return !token || (token && !isTokenExpired(token));
  },
  fetchAccessToken: () => {
    const query = `
      query refreshToken {
        refreshToken {
          accessToken
          refreshToken
        }
      }
    `;
    return fetch(process.env.REACT_APP_BASE_API, {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "refresh-token": getRefreshToken(),
      },
      body: JSON.stringify({
        query,
      }),
    });
  },
  handleFetch: (response) => {
    if (response.errors && response.errors.length) return;
    setToken({
      uid: response?.uid,
      token: response?.accessToken,
      refreshToken: response?.refreshToken,
    });
  },
  handleError: (err) => {
    console.error("[error] ", err);
    clearToken();
    window.location.replace(
      process.env.REACT_APP_LANDING_PAGE
        ? `${process.env.REACT_APP_LANDING_PAGE}&error=unauthen&redirect=${
            window?.location?.href || ""
          }`
        : ROUTE.UNAUTHEN
    );
  },
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(
      ({ message, extensions, locations, path, response }) => {
        let messagekey;
        switch (extensions?.code) {
          case "UNAUTHENTICATED":
            clearToken();
            window.location.replace(
              process.env.REACT_APP_LANDING_PAGE
                ? `${
                    process.env.REACT_APP_LANDING_PAGE
                  }&error=unauthen&redirect=${window?.location?.href || ""}`
                : ROUTE.UNAUTHEN
            );
            break;
          case "415":
            messagekey = message?.replaceAll(" ", "-").toLowerCase();
            if (messagekey === "media-type-must-be-jpeg,-jpg,-png-or-webp") {
              notification.error({
                message: i18n.t(
                  "exception.media-type-must-be-jpeg-jpg-png-or-webp"
                ),
              });
            } else {
              notification.error({ message: i18n.t(message) });
            }
            break;
          case "BAD_USER_INPUT":
            notification.error({
              message: i18n.t(`exception.${message}`),
            });
            break;
          case "FORBIDDEN":
            notification.error({
              message: i18n.t(`exception.${message}`),
            });
            break;
          default: {
            notification.error({ message: i18n.t(message) });
          }
        }
      }
    );
  }
  // else if (networkError) {
  //   notification.error({ message: "error" });
  // }
});

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_BASE_API,
});

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get("token");
  const requestUid = Cookies.get("request-uid");
  const uuid = nanoid(5);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "X-Request-ID": `${process.env.REACT_APP_NAME}-${
        requestUid ? requestUid.toUpperCase() : "none"
      }-${uuid}`,
    },
  };
});

const client = new ApolloClient({
  link: from([refreshTokenLink, errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    mutate: {},
  },
});

export default client;
