import React, { useState, useContext } from "react";
import "./TopupModal.css";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Input from "../common/InputWrapper";
import { isEmpty } from "../../utilities/util";
import { displayTextAmount } from "../../utilities/textUtil";
import Countdown from "react-countdown";
import {
  CommonContext,
  CommonDispatchContext,
} from "../../stateManagement/context/commonContext";
import { ACTIONS } from "../../stateManagement/actions/commonAction";
import { initTopupSocket } from "./TopupSocket";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_2C2P_QR_TOPUP } from "../../graphql/mutation";
import { BALANCE } from "../../graphql/query";
import { notification, Spin } from "antd";

const TopupModal = (props) => {
  const { projectID } = props;
  const { t } = useTranslation();
  const { format: f } = i18n;
  const [load, setLoad] = useState(false);
  const [price, setPrice] = useState("");
  const [step, setStep] = useState(0);
  const [qrSrc, setQR] = useState();
  const [ref1, setRef1] = useState("");
  const [ref2, setRef2] = useState("");
  const [ref3, setRef3] = useState("");
  const [hide, setHide] = useState(false);
  const dispatch = useContext(CommonDispatchContext);
  const { currentUser } = useContext(CommonContext);
  const amount = displayTextAmount(currentUser?.currentBalance || 0);
  const [createQR, { loading: loadingQR }] = useMutation(CREATE_2C2P_QR_TOPUP, {
    onCompleted: (data) => {
      const { create2C2PQrTopUp: QRData } = data || {};
      setQR(`${QRData?.qrImageUrl}`);
      setRef1(QRData?.invoiceNo);
      setRef2(QRData?.merchantId);
      setRef3(
        QRData?.projectId
          ? QRData?.projectId
          : currentUser?.uid
          ? currentUser?.uid
          : "-"
      );
    },
  });

  const { loading: balanceLoading, refetch: refetchBalance } = useQuery(
    BALANCE,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted: (response) => {
        const { profile } = response || {};
        const { currentBalance } = profile || {};
        dispatch({
          type: ACTIONS.UPDATE_CURRENT_BALANCE,
          value: currentBalance,
        });
      },
    }
  );

  const handleTextInputChange = (e) => {
    const re2 = /^\d*(\.\d{0,2})?$/;
    const text = e.target.value.trim();
    if (isEmpty(text) || re2.test(text)) {
      const priceInt = parseFloat(text);
      if (priceInt > 0 && priceInt <= 200000) {
        setPrice(text);
      } else {
        if (priceInt > 200000) {
          setPrice(200000);
        } else {
          setPrice(text);
        }
      }
    }
  };

  const baseTopupPrice =
    price[price.length - 1] === "."
      ? Number(price.slice(0, -1))
      : Number(price);

  const onCloseModal = (e) => {
    setHide(true);
    // e?.stopPropagation();
    dispatch({ type: ACTIONS.HIDE_CONFIRM_MODAL });
  };

  const onNextStepClick = (e) => {
    e.stopPropagation();
    if (price <= 0) {
      notification.error({
        message: t("please-enter-amount"),
      });
      return;
    }
    if (step === 0) {
      setLoad(true);
      const callback = async (socketID) => {
        await createQR({
          variables: {
            input: {
              price: parseFloat(price),
              socketId: socketID,
            },
          },
        });
        setStep(1);
      };

      const onTopupLoading = (socketID) => {
        setStep(2);
      };

      const onTopupSuccess = (socketID) => {
        setStep(3);
        refetchBalance();
      };
      initTopupSocket({
        callback,
        onTopupSuccess,
        onTopupLoading,
        hideLoad: () => setLoad(false),
      });
    } else {
      setStep(step + 1);
    }
  };

  const backToFirstStep = (e) => {
    e.stopPropagation();
    setStep(0);
  };

  return (
    <div
      style={{
        display: hide ? "none" : undefined,
      }}
      id="topup-modal-content-container"
    >
      <Spin spinning={loadingQR || load || balanceLoading}>
        <div
          className="flex justify-center align-center"
          id="progress-tab-container"
        >
          {[
            "top-up-amount",
            "payment-detail",
            "loading",
            "confirm-payment",
          ].map(
            (k, idx) =>
              k !== "loading" && (
                <div
                  className={`progress-tab${
                    step === idx ? " current" : ""
                  }`.trim()}
                >
                  {f(t(k), "capitalFirstEach")}
                </div>
              )
          )}
        </div>
        {step === 0 && (
          <>
            <div
              className="flex justify-center items-start flex-1"
              id="balance-input-container"
            >
              <div id="current-balance-container">
                <div className="label">
                  {f(t("current-balance"), "uppercase")}
                </div>
                <div className="amount">{amount}</div>
              </div>
              <div style={{ visibility: hide ? "hidden" : undefined }}>
                <Input
                  wrapClassName="transfer-amount-text-input"
                  placeholder="0.00"
                  onChange={handleTextInputChange}
                  value={price}
                />
                <div className="input-description">{t("hint-info-topup")}</div>
              </div>
            </div>
            <div
              id="total-topup-conclude-container"
              className="flex justify-between items-center"
            >
              <div className="label">{f(t("total-topup"), "uppercase")}</div>
              <div className="amount">
                {displayTextAmount(baseTopupPrice || 0)}
              </div>
            </div>
            <div
              id="topup-modal-footer-container"
              className="flex justify-between items-center text-center"
            >
              <div></div>
              <div id="footer-button-list" className="flex">
                <div
                  id="cancel-button"
                  className="unselectable cursor-pointer"
                  onClick={onCloseModal}
                >
                  {f(t("cancel"), "uppercase")}
                </div>
                <div
                  onClick={onNextStepClick}
                  id="next-button"
                  className="unselectable cursor-pointer"
                >
                  {f(t("next"), "uppercase")}
                </div>
              </div>
            </div>
          </>
        )}
        {step === 1 && (
          <>
            <div id="secure-payment-container" className="flex justify-center">
              <img
                src="/images/modal/secure-payment-by-scb-icon.svg"
                alt="secure-payment-icon"
              />
            </div>
            <div id="topup-amount-label" className="text-center">
              {displayTextAmount(baseTopupPrice || 0)}
            </div>
            <div id="qr-topup-img-container" className="flex justify-center">
              <img src={qrSrc} width={200} height={200} alt="sample-qr" />
            </div>
            <div id="waiting-payment-countdown">
              {`${f(t("waiting-payment"), "uppercase")}`}{" "}
              {/* <CountDownTimer date={Date.now() + 1 * 60 * 1000} />{" "} */}
              <Countdown
                date={new Date(new Date(Date.now() + 5 * 60 * 1000).getTime())}
                renderer={(props) => (
                  <>
                    {[props.minutes, props.seconds]
                      .map((a) => `${a}`.padStart(2, "0"))
                      .join(":")}
                  </>
                )}
                onComplete={(e) => {
                  if (e.total === 0) {
                    setStep(0);
                  }
                }}
              />
              {` `}
              {`${f(t("mins"), "uppercase")}`}
            </div>
            <div
              id="biller-id-reference-number-container"
              className="flex align-center justify-center"
            >
              <div
                className="additional-detail flex align-center"
                id="biller-id-row"
              >
                <div className="label">{`${f(
                  t("biller-id"),
                  "uppercase"
                )} : `}</div>
                <div className="value">{ref1}</div>
              </div>
              <div
                className="additional-detail flex align-center"
                id="transaction-number-row"
              >
                <div className="label">{`${f(
                  t("transaction-no"),
                  "uppercase"
                )} : `}</div>
                <div className="value">{ref2}</div>
              </div>
              <div
                className="additional-detail flex align-center"
                id="reference-num-3-row"
              >
                <div className="label">{`${f(
                  t("reference-num-3"),
                  "uppercase"
                )} : `}</div>
                <div className="value">{ref3}</div>
              </div>
            </div>
            <div
              className="flex justify-center items-center text-center"
              id="change-top-up-cancel-container"
            >
              <div
                onClick={onCloseModal}
                id="cancel-button"
                className="cursor-pointer"
              >
                {f(t("cancel"), "uppercase")}
              </div>
              <div
                onClick={backToFirstStep}
                id="change-top-up-amount-button"
                className="cursor-pointer"
              >
                {f(t("change-top-up-amount"), "uppercase")}
              </div>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div
              id="loader-container"
              className="flex justify-center align-center"
            >
              <img src="/images/modal/spin-icon.svg" alt="loading-icon" />
            </div>
            <div id="please-wait-label">
              {f(t("loading-wait-label"), "capitalize")}
            </div>
          </>
        )}
        {step === 3 && (
          <div id="top-up-successfull-container">
            <div
              id="topup-success-img-container"
              className="flex justify-center items-center"
            >
              <img src="/images/modal/topup-success.svg" alt="topup-success" />
            </div>
            <div id="topup-successful-label" className="text-center">
              {f(t("top-up-successful-message"), "uppercase")}
            </div>
            <div
              id="biller-id-reference-number-container"
              className="flex align-center justify-center success-page"
            >
              <div
                className="additional-detail flex align-center"
                id="biller-id-row"
              >
                <div className="label">{`${f(
                  t("biller-id"),
                  "uppercase"
                )} : `}</div>
                <div className="v">{ref1}</div>
              </div>
              <div
                className="additional-detail flex align-center"
                id="transaction-number-row"
              >
                <div className="label">{`${f(
                  t("transaction-no"),
                  "uppercase"
                )} : `}</div>
                <div className="v">{ref2}</div>
              </div>
              <div
                className="additional-detail flex align-center"
                id="reference-num-3-row"
              >
                <div className="label">{`${f(
                  t("reference-num-3"),
                  "uppercase"
                )} : `}</div>
                <div className="v">{ref3}</div>
              </div>
            </div>
            <div
              id="publish-button-container"
              className="flex justify-center flex-col items-center text-center"
            >
              {projectID ? (
                <>
                  <div
                    onClick={onCloseModal}
                    id="publish-now-button"
                    className="unselectable cursor-pointer"
                  >
                    {f(t("publish-now"), "uppercase")}
                  </div>
                  <div
                    onClick={onCloseModal}
                    id="publish-later-button"
                    className="unselectable cursor-pointer"
                  >
                    {f(t("publish-later"), "uppercase")}
                  </div>
                </>
              ) : (
                <div
                  id="publish-later-button"
                  className="unselectable cursor-pointer"
                  onClick={onCloseModal}
                >
                  {f(t("close"), "uppercase")}
                </div>
              )}
            </div>
          </div>
        )}
      </Spin>
    </div>
  );
};

export default TopupModal;
