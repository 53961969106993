import React from "react";

const { version } = require("../../../package.json");

const CommonFooter = () => {
  return (
    <div className="footer-label-detail">
      <div className="company-label">© Informata 2020</div>
      <div className="company-product-description">
        Informatar is Online Projects Suite account with the appropriate
        permission is required. v{version}
      </div>
    </div>
  );
};

export default CommonFooter;
