import React, { useEffect, useRef, useState } from "react";
import "./RecordVoice.css";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { formatTime } from "../../../utilities/formatTime";
import { Progress, Spin, Modal } from "antd";
import { useMutation } from "@apollo/client";
import { UPLOAD_FILE_PROJECT_SURVEY_VOICE } from "../../../graphql/mutation";
import { useVad } from "../../../hooks/useVad";
import { LoadingOutlined } from "@ant-design/icons";
import {
  isChrome,
  isFirefox,
  isTablet,
  isEdge,
  isIOS,
  isMobile,
  isSafari,
} from "react-device-detect";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";

const RecordVoice = ({ question, answers, onAnswer, onchangePage }) => {
  const ffmpegRef = useRef(new FFmpeg());
  const streamRef = useRef(null);
  const { start, stop } = useVad();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [playingStatus, setPlayingStatus] = useState("");
  const [deviceError, setDeviceError] = useState(false);
  const [showModalDeviceError, setShowModalDeviceError] = useState(false);
  const [voiceActivityValue, setVoiceActivityValue] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [recordTime, setRecordTime] = useState(0);
  const [recordedData, setRecordedData] = useState(null);
  const [answer, setAnswer] = useState(
    answers || {
      uid: null,
      label: null,
      value: null,
    }
  );
  const { t, i18n } = useTranslation();
  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => setDeviceError(true)
  );
  const {
    startRecording,
    stopRecording,
    recordingBlob,
    isRecording,
    recordingTime,
  } = recorderControls;

  const getText = (text, format, options = {}) =>
    i18n.format(t(text, options), format);

  const { uid, uuid, answer: initAnswer } = question;

  useEffect(() => {
    if (answer.uid) {
      onAnswer([answer]);
    }
  }, [answer]);

  useEffect(() => {
    if (recordingTime > 180 && isRecording) {
      handleRecordButtonClick();
    }
  }, [recordingTime]);

  useEffect(() => {
    const audioContainer = document.getElementById(`pretest_${uuid}`);
    if (audioContainer) {
      setPlayingStatus("");
      audioContainer.pause();
      audioContainer.currentTime = 0;
    }
  }, [onchangePage]);

  useEffect(() => {
    setAnswer({ uid: uid, uuid, value: initAnswer?.[0]?.value });
    if (initAnswer?.[0]?.value) {
      addAudioElementComplete(initAnswer?.[0]?.value, true);
    }
  }, [initAnswer]);

  useEffect(() => {
    if (!recordingBlob) return;
    console.log(recordingBlob);
    setRecordedData(recordingBlob);
  }, [recordingBlob]);

  useEffect(() => {
    if (isLoading && recordedData) {
      preHandleUploadFile();
    }
  }, [isLoading]);

  const addAudioElement = (blob) => {
    const existingAudio = document.getElementById(uuid);
    if (existingAudio) {
      existingAudio.remove();
    }

    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.id = uuid;
    audio.controls = true;

    audio.addEventListener("timeupdate", () => {
      setCurrentTime(audio.currentTime);
    });

    audio.addEventListener("ended", () => {
      setCurrentTime(0);
      setPlayingStatus("");
    });

    document.body.appendChild(audio);
  };

  const getPreviewFile = (path) => {
    const split = path.split("/");
    return `${process.env.REACT_APP_BASE_S3}/${split[split.length - 2]}/${
      split[split.length - 1]
    }`;
  };

  const addAudioElementComplete = (url, fromAnswer = false) => {
    const existingAudio = document.getElementById(`pretest_${uuid}`);
    if (existingAudio) {
      existingAudio.remove();
    }
    const audio = document.createElement("audio");
    const newUrl = recordedData
      ? URL.createObjectURL(recordedData)
      : getPreviewFile(url);
    audio.src = newUrl;
    audio.id = `pretest_${uuid}`;
    audio.controls = true;
    audio.addEventListener("loadedmetadata", () => {
      if (audio.duration === Infinity && fromAnswer) {
        audio.currentTime = 10000000;
        setTimeout(() => {
          setRecordTime(audio.duration);
          audio.currentTime = 0;
        }, 300);
      } else if (audio.duration && audio.duration !== Infinity) {
        setRecordTime(audio.duration);
        audio.currentTime = 0;
      }
    });

    audio.addEventListener("timeupdate", () => {
      setCurrentTime(audio.currentTime);
    });

    audio.addEventListener("ended", () => {
      setCurrentTime(0);
      setPlayingStatus("");
    });

    document.body.appendChild(audio);
  };

  const debounce = (value) => {
    let timeout;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setVoiceActivityValue(value);
    }, 100);
  };

  const handleRecordButtonClick = () => {
    try {
      if (!isRecording) {
        startRecording();
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            streamRef.current = stream;
            start({
              stream: streamRef.current,
              onUpdate(value) {
                debounce(value);
              },
              onVoiceStart() {
                console.log("Hello onVoiceStart");
              },
              onVoiceStop() {
                console.log("Hello onVoiceStop");
              },
            });
          })
          .catch((error) => {
            console.error("Error accessing media devices:", error);
          });
      } else {
        stop();
        setRecordTime(recordingTime);
        stopRecording();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePlayButtonClick = () => {
    const audioContainer = document.getElementById(uuid);
    if (audioContainer) {
      setPlayingStatus("PLAY");
      audioContainer.play();
    }
  };

  const handlePlayCompleteButtonClick = () => {
    const audioContainer = document.getElementById(`pretest_${uuid}`);
    console.log(audioContainer);
    if (audioContainer) {
      if (playingStatus === "PLAY") {
        setPlayingStatus("");
        audioContainer.pause();
        audioContainer.currentTime = 0;
      } else {
        setPlayingStatus("PLAY");
        audioContainer.play();
      }
    }
  };

  const handlePauseButtonClick = () => {
    setPlayingStatus("");
    const audioContainer = document.getElementById(uuid);
    if (audioContainer) {
      audioContainer.pause();
      audioContainer.currentTime = 0;
    }
    const audioContainerPreTest = document.getElementById(`pretest_${uuid}`);
    if (audioContainerPreTest) {
      audioContainerPreTest.pause();
      audioContainerPreTest.currentTime = 0;
    }
  };

  const handleRemoveButtonClick = () => {
    stopRecording();
    handlePauseButtonClick();
    setTimeout(() => {
      stop();
      setRecordTime(0);
      setRecordedData(null);
      setPlayingStatus("");
      setAnswer({
        uid: uid,
        uuid,
        value: "",
      });
    }, 300);
  };

  const renderButton = (icon, onClick, width) => (
    <img
      width={width}
      height={width}
      onClick={onClick}
      className="cursor-pointer icon-play"
      src={icon}
      style={{ zIndex: 2 }}
      alt="images-upload-icon"
    />
  );

  const renderDescription = () => {
    if (isRecording) {
      return (
        <div className="text-description mt-[20px]">
          {getText("voice-player.stop-1")} ({" "}
          <img
            src={"/images/voice-video/stop.svg"}
            alt="images-stop-icon"
            width={20}
            height={20}
          />{" "}
          ){getText("voice-player.stop-2")}
        </div>
      );
    } else if (!isRecording && recordedData) {
      return (
        <div className="text-description mt-[20px]">
          {getText("voice-player.play-1")} ({" "}
          <img
            src={"/images/voice-video/send.svg"}
            alt="images-upload-icon"
            width={20}
            height={20}
          />{" "}
          ){getText("voice-player.play-2")}
        </div>
      );
    } else {
      return <></>;
    }
  };

  const [uploadFile] = useMutation(UPLOAD_FILE_PROJECT_SURVEY_VOICE, {
    onCompleted: (data) => {
      setIsLoading(false);
      addAudioElementComplete(
        data.uploadFileVoiceProjectSurvey.result.location || ""
      );
      setAnswer({
        uid: uid,
        uuid,
        value: data.uploadFileVoiceProjectSurvey.result.location || "",
      });
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const preHandleUploadFile = async () => {
    if (!isRecording && recordedData) {
      const mimeType = recordedData.type;
      const fileType = mimeType.includes('webm') ? 'webm' : 'mp3'
      const file = new File([recordedData], `${uuid}.${fileType}`, {
        type: `audio/${fileType}`,
      });
      uploadFile({
        variables: {
          projectUid: id,
          file: file,
        },
      });
    }
  };

  const handleUploadFile = async () => {
    stopRecording();
    if (recordTime === 0) {
      setRecordTime(recordingTime);
    }
    stop();
    setTimeout(() => {
      setIsLoading(true);
      const audioContainer = document.getElementById(uuid);
      if (audioContainer) {
        setPlayingStatus("");
        audioContainer.pause();
        audioContainer.currentTime = 0;
      }
    }, 300);
  };

  const handleShowModalDeviceError = () => {
    setShowModalDeviceError(true);
  };

  const renderShowModalDeviceError = () => {
    const lang = i18n.language === "th" ? "th" : "en";
    const src = isSafari
      ? `/images/voice-video/safari_${lang}.png`
      : isFirefox
      ? `/images/voice-video/firefox_${lang}.png`
      : isEdge
      ? `/images/voice-video/edge_${lang}.png`
      : `/images/voice-video/chrome_${lang}.png`;

    return (
      <img
        width={"100%"}
        src={src}
        style={{ borderRadius: 20 }}
        alt="ErrorInfo"
      />
    );
  };

  return (
    <Spin
      indicator={<LoadingOutlined spin />}
      size="large"
      spinning={isLoading}
    >
      <Modal
        wrapClassName="error-modal"
        onCancel={() => setShowModalDeviceError(false)}
        open={showModalDeviceError}
        footer={null}
        destroyOnClose
        centered
      >
        <div className="w-full relative">
          <div
            className="absolute top-[10px] right-[20px] flex flex-row text-[#B7B9B8] text-[16px] font-bold cursor-pointer"
            onClick={() => setShowModalDeviceError(false)}
          >
            <img
              width={13}
              src="/images/voice-video/close.svg"
              alt="close"
              className="mr-2 mt-[2px]"
            />
            {getText(`close2`)}
          </div>
          {renderShowModalDeviceError()}
        </div>
      </Modal>
      <div className="image-box-voice">
        <div id="question-input" className="text-black">
          <div className="border-box">
            <AudioRecorder
              onRecordingComplete={(blob) => addAudioElement(blob)}
              recorderControls={recorderControls}
              downloadFileExtension="mp3"
            />
            {answer.value ? (
              <div className="wrap-player-complete">
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                  }}
                >
                  {renderButton(
                    playingStatus === "PLAY"
                      ? "/images/voice-video/voice-complete-stop.svg"
                      : "/images/voice-video/voice-complete-play.svg",
                    handlePlayCompleteButtonClick,
                    60
                  )}
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        cursor: "pointer",
                        overflow: "hidden",
                        width: `${(currentTime / recordTime) * 100}%`,
                        left: 20,
                        transition:
                          "width 0.3s ease-in-out, height 0.3s ease-in-out",
                      }}
                    >
                      <img
                        src={"/images/voice-video/wave.svg"}
                        alt="images-upload-icon"
                        height={55}
                      />
                    </div>
                    <img
                      style={{ marginLeft: 20 }}
                      src={"/images/voice-video/wave-transparent.svg"}
                      alt="images-upload-icon"
                      height={55}
                    />
                  </div>
                </div>
                <div>
                  {playingStatus === "PLAY"
                    ? formatTime(currentTime)
                    : formatTime(recordTime > 180 ? 180 : recordTime)}
                  {renderButton(
                    "/images/voice-video/trash.svg",
                    handleRemoveButtonClick,
                    32
                  )}
                </div>
              </div>
            ) : (
              <div className="wrap-player">
                {!isRecording && !recordedData && (
                  <>
                    <div
                      className="text-description"
                      style={{ marginBottom: 15 }}
                    >
                      {getText("voice-player.start")}
                    </div>
                    {renderButton(
                      deviceError
                        ? "/images/voice-video/voice-record-device-error.svg"
                        : "/images/voice-video/voice-record.svg",
                      deviceError
                        ? handleShowModalDeviceError
                        : handleRecordButtonClick,
                      120
                    )}
                    {deviceError && (
                      <div
                        className="button-device-error mt-[15px] cursor-pointer"
                        onClick={handleShowModalDeviceError}
                      >
                        Allow microphone
                      </div>
                    )}
                  </>
                )}
                {!isRecording && recordedData && playingStatus === "" && (
                  <div className="playing">
                    {formatTime(recordTime > 180 ? 180 : recordTime)}
                    <div
                      style={{
                        marginTop: 10,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {renderButton(
                        "/images/voice-video/trash.svg",
                        handleRemoveButtonClick,
                        45
                      )}
                      {renderButton(
                        "/images/voice-video/play.svg",
                        handlePlayButtonClick,
                        120
                      )}
                      {renderButton(
                        "/images/voice-video/send.svg",
                        handleUploadFile,
                        45
                      )}
                    </div>
                  </div>
                )}
                {!isRecording && recordedData && playingStatus === "PLAY" && (
                  <div className="playing">
                    {formatTime(currentTime)}
                    <div
                      style={{
                        marginTop: 10,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {renderButton(
                        "/images/voice-video/trash.svg",
                        handleRemoveButtonClick,
                        45
                      )}
                      <div style={{ position: "relative" }}>
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            zIndex: 999,
                            left: 10,
                            cursor: "pointer",
                          }}
                          onClick={handlePauseButtonClick}
                        >
                          <Progress
                            width={120}
                            type="circle"
                            showInfo={false}
                            strokeColor={"#44DC82"}
                            percent={(currentTime / recordTime) * 100}
                          />
                        </div>

                        {renderButton(
                          "/images/voice-video/playing.svg",
                          handlePauseButtonClick,
                          120
                        )}
                      </div>

                      {renderButton(
                        "/images/voice-video/send.svg",
                        handleUploadFile,
                        45
                      )}
                    </div>
                  </div>
                )}
                {isRecording && (
                  <div className="playing">
                    {formatTime(recordingTime)}
                    <div
                      style={{
                        marginTop: 10,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      {renderButton(
                        "/images/voice-video/trash.svg",
                        handleRemoveButtonClick,
                        45
                      )}
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {renderButton(
                          "/images/voice-video/stop.svg",
                          handleRecordButtonClick,
                          120
                        )}
                        <div
                          style={{
                            position: "absolute",
                            cursor: "pointer",
                          }}
                        >
                          <div
                            className="cursor-pointer icon-play"
                            style={{
                              width:
                                120 * (voiceActivityValue + 1) > 240
                                  ? 240
                                  : 120 * (voiceActivityValue + 1),
                              height:
                                120 * (voiceActivityValue + 1) > 240
                                  ? 240
                                  : 120 * (voiceActivityValue + 1),
                              borderRadius: 120,
                              backgroundColor: "rgba(68, 220, 130, 0.5)",
                              transition:
                                "width 0.3s ease-out, height 0.3s ease-out",
                            }}
                          />
                        </div>
                      </div>
                      {renderButton(
                        "/images/voice-video/send.svg",
                        handleUploadFile,
                        45
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            {!answer.value && renderDescription()}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default RecordVoice;
