import { ACTIONS } from "../stateManagement/actions/commonAction";
import { MODAL_TYPE } from "../config/customModalConfig";

const showTopupModal = (dispatch, e) => {
  e.stopPropagation();
  dispatch({
    type: ACTIONS.SHOW_CONFIRM_MODAL,
    modalConfig: {
      type: MODAL_TYPE.TOPUP,
      className: "topup-modal-wrapper",
      centered: true,
      maskClosable: false,
    },
  });
};

export { showTopupModal };
