import { Result, Text } from "antd";
import { Button, Input, Skeleton, Space } from "antd";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ROUTE } from "../config/common";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import {
  clearToken,
  getRefreshToken,
  getToken,
  isTokenExpired,
  setToken,
} from "../utilities/authenUtil";
import "../styles/previewFile.css";

const PreviewFileScreen = () => {
  const { t: getText, i18n } = useTranslation();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [proxyUrl, setProxyUrl] = useState("");
  const [proxyUrlDownload, setProxyUrlDownload] = useState("");
  const [fileName, setFileName] = useState("");
  const [type, setType] = useState("");
  const [urlDownload, setUrlDownload] = useState("");
  const { search } = useLocation();
  const audioRef = useRef(null);
  const dataSearch = new URLSearchParams(search);
  const pathFile = dataSearch.get("path");

  const checkExpireToken = () => {
    const token = Cookies.get("token");
    return !token || (token && !isTokenExpired(token));
  };

  useEffect(() => {
    if (pathFile) {
      if (dataSearch.get("path") && dataSearch.get("type")) {
        const split = dataSearch.get("path").split(".");
        const type = split[split.length - 1];
        setType(type.split("?")[0]);
        const splitName = split[0].split("/");
        setFileName(splitName[splitName.length - 1]);
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
  }, [pathFile]);

  useEffect(() => {
    if (pathFile) {
      console.log('isTokenExpired(Cookies.get("token")', checkExpireToken());
      if (checkExpireToken()) {
        fetchAccessToken(checkData);
      } else {
        checkData();
      }
    }
  }, [pathFile]);

  const fetchAccessToken = (onCallback) => {
    const query = `
    query refreshToken {
      refreshToken {
        accessToken
        refreshToken
      }
    }
  `;
    fetch(process.env.REACT_APP_BASE_API, {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "refresh-token": getRefreshToken(),
      },
      body: JSON.stringify({
        query,
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        console.log("datadata", data);
        const res = data?.data?.refreshToken;
        console.log(res);
        console.log("res?.accessToken", res?.accessToken);
        if (res) {
          setToken({
            uid: res?.uid,
            token: res?.accessToken,
            refreshToken: res?.refreshToken,
          });
          onCallback(res?.accessToken);
        } else {
          clearToken();
          window.location.replace(
            process.env.REACT_APP_LANDING_PAGE
              ? `${
                  process.env.REACT_APP_LANDING_PAGE
                }&error=unauthen&redirect=${window?.location?.href || ""}`
              : ROUTE.UNAUTHEN
          );
        }
      })
      .catch((e) => {
        console.error("[error] ", e);
        clearToken();
        window.location.replace(
          process.env.REACT_APP_LANDING_PAGE
            ? `${process.env.REACT_APP_LANDING_PAGE}&error=unauthen&redirect=${
                window?.location?.href || ""
              }`
            : ROUTE.UNAUTHEN
        );
      });
  };

  const checkData = async (token) => {
    console.log(Cookies.get("token"));
    console.log("tokentoken===>", token);
    await fetch(
      `${process.env.REACT_APP_BASE_DOWNLOAD_API}/project/check-file${pathFile}`,
      {
        headers: {
          Authorization: `Bearer ${token ? token : Cookies.get("token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200) {
          fetchData();
        } else if (data.statusCode === 404) {
          setError(true);
          setErrorMessage("nofile");
        } else {
          setError(true);
          setErrorMessage("errorPermission");
        }
      })
      .catch((error) => {
        console.log("errorerrorerror", error);
        setError(true);
        setErrorMessage("errorPermission");
      });
  };

  const fetchData = async () => {
    if (pathFile) {
      // const mediaUrl = `${process.env.REACT_APP_BASE_DOWNLOAD_API}/project/file${pathFile}`;
      // const proxyUrl = `/api/getfile?url=${encodeURIComponent(
      //   mediaUrl
      // )}&token=${encodeURIComponent(Cookies.get("token"))}`;
      const proxyUrl = `${process.env.REACT_APP_BASE_S3}${pathFile}`;
      setProxyUrl(proxyUrl);
      fetchConvertData();
      const intervalId = setInterval(() => {
        if (!urlDownload || urlDownload === "nofile") {
          fetchConvertData();
        } else {
          clearInterval(intervalId);
        }
      }, 10000);
    }
  };

  const fetchConvertData = async () => {
    if (pathFile) {
      const splitPath = pathFile.split(".");
      const pathFileDownload = `${splitPath[0]}_convert.${
        dataSearch.get("type") === "audio" ? "mp3" : "mp4"
      }`;

      const mediaUrl = `${process.env.REACT_APP_BASE_DOWNLOAD_API}/project/file${pathFileDownload}`;

      const downloadUrl = `/api/getfile?url=${encodeURIComponent(
        mediaUrl
      )}&token=${encodeURIComponent(Cookies.get("token"))}`;

      const proxyUrl = `${process.env.REACT_APP_BASE_S3}${pathFileDownload}`;

      await fetch(
        `${process.env.REACT_APP_BASE_DOWNLOAD_API}/project/check-file${pathFileDownload}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            setUrlDownload(downloadUrl);
            setProxyUrlDownload(proxyUrl);
          } else {
            setUrlDownload("nofile");
            setProxyUrlDownload("nofile");
          }
        })
        .catch((error) => {
          console.log(error);
          setUrlDownload("nofile");
          setProxyUrlDownload("nofile");
        });
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 180;
      setTimeout(() => {
        audioRef.current.currentTime = 0;
      }, 1000);
    }
  };

  if (error) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img
          width={200}
          height={200}
          className=""
          alt=""
          src="/images/common/expired-file.svg"
        />
        <div
          style={{
            fontSize: 20,
            fontWeight: "bold",
            color: "#34343F",
            marginTop: 20,
          }}
        >
          {i18n.format(getText("expired-title"))}
        </div>
        <div
          style={{
            fontSize: 16,
            fontWeight: 500,
            color: "#A0A5B2",
            marginTop: 10,
          }}
        >
          {errorMessage === "errorPermission"
            ? i18n.format(getText("expired-description-permission"))
            : i18n.format(getText("expired-description"))}
        </div>
      </div>
    );
  }

  const handleError = async (e) => {
    console.log(e);
    console.log("proxyUrl=====>", proxyUrl);
  };

  const handleDownloadFile = (token) => {
    const fileType = dataSearch.get("type") === "audio" ? "mp3" : "mp4";

    const splitPath = pathFile.split(".");
    const pathFileDownload = `${splitPath[0]}_convert.${
      dataSearch.get("type") === "audio" ? "mp3" : "mp4"
    }`;

    const fileNameWithExtension = `${fileName}_convert.${fileType}`;
    const mediaUrl = `${process.env.REACT_APP_BASE_DOWNLOAD_API}/project/file${pathFileDownload}`;

    const downloadUrl = `/api/getfile?url=${encodeURIComponent(
      mediaUrl
    )}&token=${encodeURIComponent(token ? token : Cookies.get("token"))}`;

    fetch(downloadUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileNameWithExtension;
        link.click();
      })
      .catch(console.error);
  };

  return (
    <div>
      {/* <div className="h-[60px] w-full bg-white"></div> */}
      <div className="preview-file-wrapper">
        <div className="preview-file-card">
          <img
            width={"100%"}
            className="unselectable"
            draggable="false"
            src="/images/preview-survey/informata-header.png"
            alt="Informata Header"
          />
          <div className="bg-[#F7F8FA] p-[25px] my-[20px] w-full flex items-center justify-center">
            {proxyUrl && proxyUrlDownload && (
              <div className="">
                {dataSearch.get("type") === "video" ? (
                  <video
                    preload="auto"
                    width="500"
                    controls
                    onError={handleError}
                    title={`${fileName}.${type}`}
                  >
                    <source
                      src={
                        type === "webm" || proxyUrlDownload === "nofile"
                          ? proxyUrl
                          : proxyUrlDownload
                      }
                      type={`video/${
                        type === "webm" || proxyUrlDownload === "nofile"
                          ? type
                          : "mp4"
                      }`}
                    />
                  </video>
                ) : (
                  <audio
                    ref={audioRef}
                    onLoadedMetadata={handleLoadedMetadata}
                    id="audio_player"
                    preload="auto"
                    style={{ display: "flex" }}
                    width="400"
                    controls
                    onError={handleError}
                    title={`${fileName}.${type}`}
                  >
                    <source
                      src={
                        type === "webm" || proxyUrlDownload === "nofile"
                          ? proxyUrl
                          : proxyUrlDownload
                      }
                      type={`audio/${
                        type === "webm" || proxyUrlDownload === "nofile"
                          ? type
                          : "mp3"
                      }`}
                    />
                  </audio>
                )}
              </div>
            )}
          </div>
          {urlDownload && (
            <>
              <div className="text-[#454456] text-[18px] font-bold">
                {getText("preview-file.download")}
              </div>
              <div className="py-[10px] preview-file">
                {urlDownload !== "nofile" ? (
                  <div className="flex items-center justify-center flex-col">
                    <div className="text-[#454456] text-[16px] mb-[5px]">
                      {getText("preview-file.download-description")}
                    </div>
                    <Button
                      onClick={() => {
                        if (checkExpireToken()) {
                          fetchAccessToken(handleDownloadFile);
                        } else {
                          handleDownloadFile();
                        }
                      }}
                      style={{
                        width: 400,
                        backgroundColor: "#22A85B",
                        border: "none",
                        borderRadius: 10,
                        height: 45,
                        color: "#FFF",
                        fontSize: 16,
                      }}
                      className="bg-[#22A85B] text-[#FFF]"
                    >
                      {getText("preview-file.download")}
                    </Button>
                  </div>
                ) : (
                  <Button
                    loading={true}
                    style={{
                      backgroundColor: "#F7F8FA",
                      border: "none",
                      borderRadius: 10,
                      height: 45,
                      color: "#454456",
                      fontSize: 16,
                    }}
                    className="bg-[#F7F8FA] text-[#454456]"
                  >
                    {getText("preview-file.converting-file")}
                  </Button>
                )}
              </div>
            </>
          )}

          {urlDownload === "nofile" && (
            <div className="text-[#B7B9B8] text-[16px]">
              {getText("preview-file.convert-description")}
            </div>
          )}
        </div>
        <div id="powered-by">
          {getText("preview-copyright", {
            name: process.env.REACT_APP_COMPANY_NAME,
          })}
        </div>
      </div>
    </div>
  );
};

export default PreviewFileScreen;
