/* eslint-disable no-useless-escape */
const isEmpty = (obj) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;
const notEmpty = (obj) => !isEmpty(obj);
const isFunction = (checker) => typeof checker === "function";
const isInteger = (tester) => /^\d+$/.test(tester);
const notInteger = (tester) => !isInteger(tester);
const isEmail = (tester) =>
  tester.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
const getFlag = (country) => `https://countryflagsapi.com/png/${country}`;

const validateThaiCitizenID = (id) => {
  // eslint-disable-next-line eqeqeq
  if (id.length != 13 || id.charAt(0).match(/[09]/)) return false;

  var sum = 0;
  for (let i = 0; i < 12; i++) {
    sum += parseInt(id.charAt(i)) * (13 - i);
  }

  // eslint-disable-next-line eqeqeq
  if ((11 - (sum % 11)) % 10 != parseInt(id.charAt(12))) {
    return false;
  }

  return true;
};

const trimDash = (value) => value?.split("-")?.join("");
const isExpireDate = (date) => {
  const oneDay = 60 * 60 * 24 * 1000;
  const currentTime = +new Date();
  const createdAt = +new Date(date);
  const compareDatesBoolean = currentTime - createdAt > oneDay;
  return compareDatesBoolean;
};

const formatterNumber = (val) => {
  if (!val) return 0;
  return `${val}`
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    .replace(/\.(?=\d{0,2}$)/g, ",");
};

const parserNumber = (val) => {
  if (!val) return 0;
  return Number.parseFloat(val.replace(/\$\s?|(\.*)/g, ""));
};

export {
  getFlag,
  isEmpty,
  notEmpty,
  isFunction,
  isInteger,
  notInteger,
  isEmail,
  validateThaiCitizenID,
  trimDash,
  isExpireDate,
  formatterNumber,
  parserNumber,
};
