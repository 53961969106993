import { useRef } from "react";
import vad from "voice-activity-detection";

export const useVad = () => {
  const audioContextRef = useRef(null);

  const start = ({ audioContext, stream, onVoiceStart, onVoiceStop, onUpdate }) => {
    try {
      audioContextRef.current = audioContext || new AudioContext();
      if (!audioContextRef.current) {
        console.error("AudioContext not initialized.");
        return;
      }

      if (!(stream instanceof MediaStream)) {
        console.error("Invalid MediaStream provided.");
        return;
      }

      vad(audioContextRef.current, stream, {
        onVoiceStart,
        onVoiceStop,
        onUpdate,
      });
    } catch (error) {
      console.error("Error starting VAD:", error);
    }
  };

  const suspend = () => {
    try {
      if (audioContextRef.current && audioContextRef.current.state === "running") {
        audioContextRef.current.suspend();
      }
    } catch (error) {
      console.error("Error suspending AudioContext:", error);
    }
  };

  const stop = () => {
    try {
      if (audioContextRef.current) {
        audioContextRef.current.close();
        audioContextRef.current = null;
      }
    } catch (error) {
      console.error("Error stopping AudioContext:", error);
    }
  };

  return { start, stop, suspend };
};