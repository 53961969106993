// for gql mutation config
import { gql } from "@apollo/client";

// survey dashboard page
const CREATE_PROJECT = gql`
  mutation createProject($input: ProjectServeyCreateInput!) {
    createProjectSurvey(input: $input) {
      uid
    }
  }
`;

const RENAME_PROJECT = gql`
  mutation renameProjectSurvey($input: ProjectServeyRenameInput!) {
    renameProjectSurvey(input: $input) {
      messageCode
      message
    }
  }
`;

const DUPLICATE_PROJECT = gql`
  mutation duplicateProjectSurvey($input: ProjectServeyDuplicateInput!) {
    duplicateProjectSurvey(input: $input) {
      messageCode
      message
    }
  }
`;

const SHARE_PROJECT = gql`
  mutation shareProject($uid: String!, $shareList: [String!]!) {
    shareProjectSurvey(uid: $uid, shareList: $shareList) {
      messageCode
      message
    }
  }
`;

const DELETE_PROJECT = gql`
  mutation removeProjectSurvey($uid: String!) {
    removeProjectSurvey(uid: $uid) {
      messageCode
      message
    }
  }
`;

const ACTIVE_PROJECT = gql`
  mutation activeProject($uid: String!) {
    activateProjectSurvey(uid: $uid) {
      messageCode
      message
    }
  }
`;

const RESUME_PROJECT = gql`
  mutation resumeProject($uid: String!) {
    resumeProjectSurvey(uid: $uid) {
      messageCode
      message
    }
  }
`;

const PAUSE_PROJECT = gql`
  mutation pauseProject($uid: String!) {
    pauseProjectSurvey(uid: $uid) {
      messageCode
      message
    }
  }
`;

const STOP_PROJECT = gql`
  mutation stopProject($uid: String!) {
    stopProjectSurvey(uid: $uid) {
      messageCode
      message
    }
  }
`;

const FAVORITE_PROJECT = gql`
  mutation favoriteProject($uid: String!, $favorite: Boolean!) {
    changeFavoriteProjectSurvey(uid: $uid, favorite: $favorite) {
      messageCode
      message
    }
  }
`;

// account page
const PROFILE = gql`
  mutation updateProfile($input: AccountUpdateContactInput!) {
    updateContactAccount(input: $input) {
      message
      messageCode
    }
  }
`;

const UPDATE_LANGUAGE = gql`
  mutation updateLanguage($input: AccountUpdateLanguageInput!) {
    updateAccountLanguage(input: $input) {
      message
      messageCode
    }
  }
`;

const PROJECT_SETTINGS = gql`
  mutation updateProjectSetting($input: ProjectServeyUpdateInput!) {
    updateProjectSetting(input: $input) {
      messageCode
      message
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation changePassword($input: AccountChangePasswordInput!) {
    changePassword(input: $input) {
      messageCode
      message
    }
  }
`;

const UPDATE_QA_CREATION = gql`
  mutation updateQABuilder($input: ProjectUpdateQABuilderInput!) {
    updateQABuilder(input: $input) {
      projectUid
      status
      createdAt
      updatedAt
      cube {
        cubeUid
        uuid
        cubeName
        minimumTime
        maximumTime
        order
        question {
          questionUid
          uuid
          title
          type
          answerType
          matrixType
          slideType
          format
          mandatory
          scale {
            scaleUid
            uuid
            label
            point
            naming
            order
          }
          order
          min
          max
          gridLines
          videoUrl
          choice {
            uuid
            label
            rightLabel
            order
          }
          imageName
          imageUrl
          imagePath
          imageType
        }
      }
    }
  }
`;
const SUBMIT_APPROVAL_PROJECT_SURVEY = gql`
  mutation submitApprovalProjectSurvey($uid: String!) {
    submitApprovalProjectSurvey(uid: $uid) {
      message
      messageCode
    }
  }
`;
const CREATE_REQUEST_TOPUP = gql`
  mutation createRequestQrTopUp($input: IQrCode!) {
    createQrTopUp(input: $input) {
      qrRawData
      csExtExpiryTime
      qrcodeId
      currencyName
      merchantId
      merchantName
      amount
      invoice
      ref1
      ref2
      ref3
      qrImage
    }
  }
`;
const REEDITING_PROJECT_SETTING = gql`
  mutation reEditProject($projectUid: String!) {
    reEditProject(projectUid: $projectUid) {
      message
      messageCode
    }
  }
`;
const CREATE_DIRECT_WALLET_TOPUP = gql`
  mutation createDirectWalletTopUp($projectUid: String!) {
    createDirectWalletTopUp(projectUid: $projectUid) {
      message
      messageCode
    }
  }
`;

const CREATE_QR_TOPUP = gql`
  mutation createQrTopUp($input: IQrCode!) {
    createQrTopUp(input: $input) {
      messageCode
      message
      qrRawData
      qrImage
      csExtExpiryTime
      qrcodeId
      currencyName
      merchantId
      merchantName
      amount
      invoice
      ref1
      ref2
      ref3
    }
  }
`;

const UPDATE_STUDY_ANSWER = gql`
  mutation updateStudyAnswer(
    $studyUid: ID!
    $projectUid: ID!
    $cubeUid: ID!
    $answer: [SubmissionStudyAnswerInput!]!
    $completed: Boolean
    $currentLogic: String!
    $currentStage: Int
    $timeSpent: Int
    $timeStudy: Int!
    $shuffleLogic: String
  ) {
    updateStudyAnswer(
      studyUid: $studyUid
      projectUid: $projectUid
      cubeUid: $cubeUid
      answer: $answer
      completed: $completed
      currentLogic: $currentLogic
      currentStage: $currentStage
      timeSpent: $timeSpent
      timeStudy: $timeStudy
      shuffleLogic: $shuffleLogic
    ) {
      earn
      currency
      message
      messageCode
    }
  }
`;

const RETAKE_SURVEY = gql`
  mutation retakeProjectSurvey($projectUid: String!, $studyUid: String!) {
    retakeStudy(projectUid: $projectUid, studyUid: $studyUid) {
      messageCode
      message
    }
  }
`;
const UPLOAD_IMAGE = gql`
  mutation uploadImageTool($projectUid: String!, $imageTool: Upload!) {
    uploadImageTool(projectUid: $projectUid, imageTool: $imageTool) {
      messageCode
      message
      imageName
      imageType
      imagePath
      imageUrl
    }
  }
`;

const UPDATE_PROJECT_LOGIC = gql`
  fragment LogicFragment on Logic {
    uuid
    type
    cubeUuid
    condition {
      uuid
      conjunction
      conditionType
      questionUuid
      questionType
      choiceUuid
      scaleUuid
      mode
      operator
      value
      ignoreCase
      order
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
    randomlyPresent
    countPresent
    countPreview
    order
    collapse
  }
  mutation updateProjectLogic($input: ProjectUpdateLogicInput!) {
    updateProjectLogic(input: $input) {
      projectUid
      createdAt
      updatedAt
      status
      logic {
        ...LogicFragment
        logic {
          ...LogicFragment
          logic {
            ...LogicFragment
            logic {
              ...LogicFragment
            }
          }
        }
      }
    }
  }
`;

const SHARE_ADD_PROJECT = gql`
  mutation addShareProject($uid: String!, $shareList: [String!]!) {
    addShareProject(projectUid: $uid, addShareList: $shareList) {
      messageCode
      message
    }
  }
`;

const CREATE_AUDIENCE = gql`
  mutation CreateAudience(
    $projectUid: ID!
    $typeUid: ID!
    $need: [String!]!
    $needValues: [AudienceNeedValue!]
  ) {
    createAudience(
      projectUid: $projectUid
      typeUid: $typeUid
      need: $need
      needValues: $needValues
    ) {
      message
      messageCode
    }
  }
`;

const UPDATE_AUDIENCE = gql`
  mutation UpdateAudience(
    $projectUid: ID!
    $audienceUid: ID!
    $typeUid: ID!
    $need: [String!]!
    $needValues: [AudienceNeedValue!]
  ) {
    updateAudience(
      projectUid: $projectUid
      audienceUid: $audienceUid
      typeUid: $typeUid
      need: $need
      needValues: $needValues
    ) {
      messageCode
      message
    }
  }
`;

const REMOVE_SHARE_PROJECT = gql`
  mutation removeShareProject(
    $projectUid: String!
    $removeShareList: [String!]!
  ) {
    removeShareProject(
      projectUid: $projectUid
      removeShareList: $removeShareList
    ) {
      messageCode
      message
    }
  }
`;

const REMOVE_AUDIENCE = gql`
  mutation RemoveAudience($projectUid: String!, $audienceUid: String!) {
    removeAudience(projectUid: $projectUid, audienceUid: $audienceUid) {
      messageCode
      message
    }
  }
`;

const UPLOAD_PROFILE_IMAGE = gql`
  mutation uploadProfilePic($profileImage: Upload!) {
    uploadProfilePic(profileImage: $profileImage) {
      messageCode
      message
    }
  }
`;

const UPLOAD_FILE_PROJECT_SURVEY = gql`
  mutation uploadFileProjectSurvey($profileImage: Upload!, $projectUid: String!, $pathFileOld: String) {
    uploadFileProjectSurvey(projectUid: $projectUid, pathFileOld: $pathFileOld, profileImage: $profileImage) {
      messageCode
      message
      result {
        message
        filename
      path
      endpoint
      location
      }
    }
  }
`;

const UPLOAD_FILE_PROJECT_SURVEY_VIDEO = gql`
  mutation uploadFileVideoProjectSurvey($file: Upload!, $projectUid: String!, $pathFileOld: String) {
    uploadFileVideoProjectSurvey(projectUid: $projectUid, pathFileOld: $pathFileOld, file: $file) {
      messageCode
      message
      result {
        message
        filename
      path
      endpoint
      location
      }
    }
  }
`;

const UPLOAD_FILE_PROJECT_SURVEY_VOICE = gql`
  mutation uploadFileVoiceProjectSurvey($file: Upload!, $projectUid: String!, $pathFileOld: String) {
    uploadFileVoiceProjectSurvey(projectUid: $projectUid, pathFileOld: $pathFileOld, file: $file) {
      messageCode
      message
      result {
        message
        filename
      path
      endpoint
      location
      }
    }
  }
`;

const RESEND_CHANGE_EMAIL = gql`
  mutation resendChangeEmail {
    resendChangeEmail {
      messageCode
      message
    }
  }
`;

const CANCEL_CHANGE_EMAIL = gql`
  mutation cancelChangeEmail {
    cancelChangeEmail {
      messageCode
      message
    }
  }
`;

const UPDATE_READ_FLAG = gql`
  mutation updateFlagReadActive($notificationUid: String!) {
    updateFlagReadActive(notificationUid: $notificationUid) {
      messageCode
      message
    }
  }
`;
const UPDATE_READ_FLAG_ALL = gql`
  mutation UpdateFlagReadAllActive {
    updateFlagReadAllActive {
      message
      messageCode
    }
  }
`;
const DELETE_NOTIFICATION_ALL = gql`
  mutation deleteNotificationAll {
    deleteNotificationAll {
      message
      messageCode
    }
  }
`;

const INC_COUNT_PRESENT = gql`
  mutation increaseCountPresent(
    $projectUid: ID!
    $studyUid: ID!
    $logicUid: [ID!]!
  ) {
    increaseCountPresent(
      projectUid: $projectUid
      studyUid: $studyUid
      logicUid: $logicUid
    ) {
      messageCode
      message
    }
  }
`;

const INC_COUNT_PREVIEW = gql`
  mutation increaseCountPreview($projectUid: ID!, $logicUid: [ID!]!) {
    increaseCountPreview(projectUid: $projectUid, logicUid: $logicUid) {
      messageCode
      message
    }
  }
`;

const CREATE_2C2P_QR_TOPUP = gql`
  mutation create2C2PQrTopUp($input: I2C2PQrCode!) {
    create2C2PQrTopUp(input: $input) {
      messageCode
      message
      invoiceNo
      merchantId
      projectId
      qrImageUrl
      csExtExpiryTime
      currencyName
      amount
    }
  }
`;
const CREATE_REWARD = gql`
  mutation CreateReward($input: RewardCreateInput!, $files: [Upload!]) {
    createReward(input: $input, files: $files) {
      uid
      message
      messageCode
    }
  }
`;
const UPDATE_REWARD = gql`
  mutation UpdateReward($input: RewardUpdateInput!, $files: [Upload!]) {
    updateReward(input: $input, files: $files) {
      message
      messageCode
    }
  }
`;
const DELETE_REWARD = gql`
  mutation DeleteReward($uid: String!) {
    deleteReward(uid: $uid) {
      message
      messageCode
    }
  }
`;
const DELETE_REWARD_FILE = gql`
  mutation DeleteRewardFile($uid: String!, $rewardUid: String!) {
    deleteRewardFile(uid: $uid, rewardUid: $rewardUid) {
      message
      messageCode
    }
  }
`;
const UPDATE_STATUS_REWARD = gql`
  mutation UpdateStatusReward($uid: String!, $status: String!) {
    updateStatusReward(uid: $uid, status: $status) {
      message
      messageCode
    }
  }
`;
const GENNARATE_CODE = gql`
  mutation CreateCodeAudience($text: String!) {
    createCodeAudience(text: $text) {
      code
    }
  }
`;

const FAVORITE_REWARD = gql`
  mutation favoriteReward($uid: String!, $favorite: Boolean!) {
    changeFavoriteReward(uid: $uid, favorite: $favorite) {
      messageCode
      message
    }
  }
`;

export {
  CREATE_PROJECT,
  RENAME_PROJECT,
  DUPLICATE_PROJECT,
  SHARE_PROJECT,
  DELETE_PROJECT,
  ACTIVE_PROJECT,
  RESUME_PROJECT,
  PAUSE_PROJECT,
  STOP_PROJECT,
  FAVORITE_PROJECT,
  FAVORITE_REWARD,
  PROFILE,
  UPDATE_LANGUAGE,
  CHANGE_PASSWORD,
  PROJECT_SETTINGS,
  UPDATE_QA_CREATION,
  SUBMIT_APPROVAL_PROJECT_SURVEY,
  CREATE_REQUEST_TOPUP,
  REEDITING_PROJECT_SETTING,
  CREATE_QR_TOPUP,
  CREATE_DIRECT_WALLET_TOPUP,
  UPDATE_STUDY_ANSWER,
  RETAKE_SURVEY,
  UPLOAD_IMAGE,
  UPDATE_PROJECT_LOGIC,
  SHARE_ADD_PROJECT,
  REMOVE_SHARE_PROJECT,
  CREATE_AUDIENCE,
  UPDATE_AUDIENCE,
  REMOVE_AUDIENCE,
  UPLOAD_PROFILE_IMAGE,
  RESEND_CHANGE_EMAIL,
  CANCEL_CHANGE_EMAIL,
  UPDATE_READ_FLAG,
  INC_COUNT_PRESENT,
  INC_COUNT_PREVIEW,
  CREATE_2C2P_QR_TOPUP,
  CREATE_REWARD,
  UPDATE_REWARD,
  DELETE_REWARD,
  UPDATE_STATUS_REWARD,
  GENNARATE_CODE,
  DELETE_REWARD_FILE,
  UPDATE_READ_FLAG_ALL,
  DELETE_NOTIFICATION_ALL,
  UPLOAD_FILE_PROJECT_SURVEY,
  UPLOAD_FILE_PROJECT_SURVEY_VIDEO,
  UPLOAD_FILE_PROJECT_SURVEY_VOICE
};
