import { Result, Text } from "antd";
import { useTranslation } from "react-i18next";
const ExpiredFileScreen = () => {
  const { t: getText, i18n } = useTranslation();
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <img
        width={200}
        height={200}
        className=""
        alt=""
        src="/images/common/expired-file.svg"
      />
      <div
        style={{
          fontSize: 20,
          fontWeight: "bold",
          color: "#34343F",
          marginTop: 20,
        }}
      >
        {i18n.format(getText("expired-title"))}
      </div>
      <div
        style={{
          fontSize: 16,
          fontWeight: 500,
          color: "#A0A5B2",
          marginTop: 10,
        }}
      >
        {i18n.format(getText("expired-description"))}
      </div>
    </div>
  );
};

export default ExpiredFileScreen;
