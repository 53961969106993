import { io } from "socket.io-client";
import { isFunction } from "../../utilities/util";

let socket;

const initTopupSocket = (payload) => {
  socket = io(process.env.REACT_APP_SOCKET_PATH, {
    reconnectionDelayMax: 1000,
    reconnectionDelay: 1000,
    reconnectionAttempts: Infinity,
  });
  socket.on("connect", (data) => {
    const socketID = socket.id;
    const { callback, hideLoad } = payload;
    if (isFunction(callback)) {
      callback(socketID);
    }
    hideLoad();
  });
  socket.on("topupPayment", (data) => {
    const socketID = socket.id;
    const { socketId, messageCode } = data;
    if (
      socketId === socketID &&
      isFunction(payload.onTopupLoading) &&
      messageCode === "TOPUP_PROCESSING"
    ) {
      payload.onTopupLoading();
    } else if (socketId === socketID && isFunction(payload.onTopupSuccess)) {
      payload.onTopupSuccess(data);
      socket.disconnect();
    }
  });
};

export { initTopupSocket };
