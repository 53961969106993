import { createContext, useReducer } from "react";
import CommmonReducer from "../reducers/commonReducer";
const initState = {
  notifications: 10,
  balance: 0,
  currentCredit: 0,
  recentProjectID: null,
  isShowModal: false,
  modalConfig: null,
  currentUser: {},
  globalLoading: true,
  timeStudyCounter: 0,
};

const CommonContext = createContext();
const CommonDispatchContext = createContext();

const CommonProvider = ({ children }) => {
  const [value, dispatch] = useReducer(CommmonReducer, initState);
  return (
    <CommonContext.Provider value={value}>
      <CommonDispatchContext.Provider value={dispatch}>
        {children}
      </CommonDispatchContext.Provider>
    </CommonContext.Provider>
  );
};

export { CommonContext, CommonDispatchContext, CommonProvider };
