import React from 'react';
import { CommonProvider } from './stateManagement/context/commonContext';

const ContextBundler = ({ children }) => {
  return (
    <CommonProvider>
      {children}
    </CommonProvider>
  );
};

export default ContextBundler;