import { useEffect, useContext } from "react";
import { Navigate } from "react-router";
import { ROUTE } from "../../config/common";
import { checkLogin } from "../../utilities/authenUtil";
import { useLocation } from "react-router-dom";
import {
  CommonContext,
  CommonDispatchContext,
} from "../../stateManagement/context/commonContext";
import { ACTIONS } from "../../stateManagement/actions/commonAction";

const ProtectedRoute = ({ children }) => {
  const { pathname } = useLocation();
  const { isLogin, role, errorMessage } = checkLogin();
  const dispatch = useContext(CommonDispatchContext);
  const { globalLoading } = useContext(CommonContext);

  useEffect(() => {
    if (isLogin && globalLoading && dispatch) {
      dispatch({
        type: ACTIONS.HIDE_GLOBAL_LOADING,
      });
    }
  }, [isLogin, globalLoading, dispatch]);

  const splitPathname = pathname?.split("/").filter((a) => a);
  const redirectPath = process.env.REACT_APP_LANDING_PAGE
    ? `${process.env.REACT_APP_LANDING_PAGE}&error=${
        errorMessage || "unauthen"
      }&redirect=${window?.location?.href || ""}`
    : ROUTE.UNAUTHEN;

  // redirect with role
  if (isLogin && role !== "RESEARCHER") {
    if (
      role === "PARTICIPANT" &&
      process.env.REACT_APP_PARTICIPANT_PAGE &&
      splitPathname[0] !== "studies"
    ) {
      window.location.replace(process.env.REACT_APP_PARTICIPANT_PAGE);
    } else {
      if (splitPathname[0] !== "studies") {
        // case no path in env
        return <Navigate to={ROUTE.UNAUTHEN} replace />;
      }
    }
  }

  return isLogin ? (
    children
  ) : redirectPath === ROUTE.UNAUTHEN ? (
    <Navigate to={redirectPath} replace />
  ) : (
    window.location.replace(redirectPath)
  );
};

export default ProtectedRoute;
